<template>
  <div class="twitter-preview-container mb-3" v-if="twitterProfile">
<!--    <div class="ad-card">
      <div class="ad-placeholder">
        <div class="line long"></div>
        <div class="line medium"></div>
        <div class="line long"></div>
        <div class="circles">
          <div class="circle"></div>
          <div class="circle"></div>
          <div class="circle"></div>
          <div class="circle"></div>
        </div>
      </div>
    </div>-->
    <div class="ad-card">
      <div class="ad-content">

        <div class="text w-100">
          <div>
              <img :src="twitterProfile.profile_image_url"
                   style="float: left"
                   class="profile_pic"
                   alt="Profile Picture">

            <span class="name text-black">{{ twitterProfile.name }}</span>
            <span class="verified">✔</span>
            <a target="_blank"
               :href="`https://x.com/${twitterProfile.username}`">
              <span class="handle">@{{ twitterProfile.username }}</span>
            </a>
          </div>

          <div style="margin-left: 40px">
            <div class="message text-black">{{ ad.creative.ad_text }}</div>

            <carousel v-if="ad.creative.media.length" autoplay="3000" :wrapAround="true">
              <template v-for="(img, j) in ad.creative.media"
                        :key="'img_'+j+'_'+i">
                <slide>
                  <img class="twitter_post_img mt-2"
                       :src="img" />
                </slide>
              </template>

              <template #addons>
                <pagination />
              </template>
            </carousel>

            <div class="d-flex justify-content-between mt-3">
              <img class="action_icon" src="/images/x_icons/comment.png" />
              <img class="action_icon" src="/images/x_icons/retweet.png" />
              <img class="action_icon" src="/images/x_icons/like.png" />
              <img class="action_icon" src="/images/x_icons/share.png" />
            </div>

            <div class="ad-promoted">
              <img class="action_icon"
                   src="/images/x_icons/promoted.png"
                   style="height: 15px !important; width: auto !important;" />
              Promoted
            </div>
          </div>
        </div>
      </div>
    </div>
<!--    <div class="ad-card">
      <div class="ad-placeholder">
        <div class="line long"></div>
        <div class="line medium"></div>
        <div class="line long"></div>
        <div class="circles">
          <div class="circle"></div>
          <div class="circle"></div>
          <div class="circle"></div>
          <div class="circle"></div>
        </div>
      </div>
    </div>-->
  </div>
</template>

<script>
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide, Pagination } from 'vue3-carousel'

export default {
  props: ['twitterProfile', 'ad'],
  components: {Carousel, Slide, Pagination}
}
</script>

<style scoped>
.twitter-preview-container {
  width: 400px;
  background-color: #ffffff;
  border: 1px solid #e1e8ed;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  float: right;
}
.ad-card {
  padding: 16px;
  border-bottom: 1px solid #e1e8ed;
}
.ad-card:last-child {
  border-bottom: none;
}
.ad-placeholder {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;
  background-color: #f0f2f5;
}
.ad-placeholder .line {
  height: 10px;
  background-color: #e0e0e0;
  border-radius: 5px;
  margin: 10px 0;
}
.ad-placeholder .line.short {
  width: 50px;
}
.ad-placeholder .line.medium {
  width: 150px;
}
.ad-placeholder .line.long {
  width: 300px;
}
.ad-placeholder .circles {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.ad-placeholder .circle {
  width: 30px;
  height: 30px;
  background-color: #e0e0e0;
  border-radius: 50%;
}
.ad-content {
  display: flex;
  align-items: center;
}
.profile_pic {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}
.ad-content .text {
  font-size: 14px;
}
.ad-content .text .name {
  font-weight: bold;
  margin-right: 5px;
}
.ad-content .text .handle {
  color: #657786;
}
.ad-content .text .verified {
  color: #1da1f2;
  margin-left: 5px;
}
.ad-content .text .message {
  margin-top: 5px;
}
.ad-promoted {
  font-size: 12px;
  color: #657786;
  margin-top: 8px;
}
.action_icon {
  opacity: 0.3;
  height: 20px !important;
  width: 20px !important;
}
.twitter_post_img {
  width: 100%;
  max-height: 200px;
  border-radius: 15px;
  object-fit: cover;
}
</style>