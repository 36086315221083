<template>
  <div class="modal fade"
       id="modal-twitter-media-uploader"
       tabindex="-1"
       role="dialog"
       aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="modal-add-cardLabel">Media</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div><!--end modal-header-->
        <div class="modal-body">
          <div class="image-container">
            <img :src="image"
                 v-for="image in allMedia"
                 :key="'image_'+image"
                 @click="selectMedia(image)"
                 :class="['img-fluid rounded selectable-image', {'selected': selected.includes(image)}]" />
          </div>

          <p class="text-danger font-weight-bold text-center"
             v-if="selected.length > 4">You can include up to 4 photos</p>

          <div class="row mt-5">
            <div class="col-12 text-center">
              <button class="btn btn-sm btn-primary" @click="triggerFileInput">Upload from computer</button>
              <input type="file"
                     ref="fileInput"
                     accept="image/*"
                     @change="uploadMedia" style="display: none;" />
            </div>
          </div>
        </div><!--end modal-body-->
        <div class="modal-footer">
          <button type="button"
                  :disabled="!selected.length || selected.length > 4"
                  @click.prevent="saveMedia"
                  class="btn btn-success btn-sm"><i class="fas fa-save"></i> Save</button>
        </div>
      </div><!--end modal-content-->
    </div><!--end modal-dialog-->
  </div><!--end modal-->
</template>

<script>
import {mapGetters} from "vuex";

export default {
  data() {
    return {
      ad: null,
      selected: [],
      goToNextStep: false,
      cleanOtherAds: false,
    }
  },
  computed: {
    ...mapGetters({
      amazonProduct: 'googleWizard/getAmazonProduct',
      uploadedMedia: 'twitterWizardModule/getUploadedMedia',
    }),
    allMedia() {
      const amazonMedia =this.amazonProduct ? [...new Set(Object.values(this.amazonProduct.images))] : [];
      return [...amazonMedia, ...this.uploadedMedia];
    }
  },
  mounted() {
    this.modal = new window.bootstrap.Modal(document.getElementById('modal-twitter-media-uploader'));
  },
  methods: {
    show(ad, gotoNextStep = false, cleanOtherAds = false) {
      this.goToNextStep = gotoNextStep;
      this.cleanOtherAds = cleanOtherAds;
      this.ad = ad;

      if (cleanOtherAds) {
        this.selected = [];
      } else {
        this.selected = [...ad.creative.media];
      }

      this.modal.show();
    },
    hide() {
      this.modal.hide();
    },
    selectMedia(src) {
      const index = this.selected.indexOf(src);

      if (index === -1) {
        this.selected.push(src);
      } else {
        this.selected.splice(index, 1);
      }
    },
    triggerFileInput() {
      this.$refs.fileInput.click();
    },
    uploadMedia(event) {
      const file = event.target.files[0];
      if (file) {
        const formData = new FormData();
        formData.append('media', file);

        this.$store.dispatch('twitterWizardModule/uploadMedia', formData);
      }
    },
    saveMedia() {
      this.ad.creative.media = [...this.selected];
      this.hide();

      if (this.goToNextStep) {
        this.$emit('changeStep', 11);
      }
    }
  }
}
</script>

<style scoped>
.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.selectable-image {
  width: 100px;
  height: 100px;
  margin: 5px;
  cursor: pointer;
  transition: border 0.3s;
}
.selected {
  border: 2px solid #007bff;
  border-radius: 5px;
}
</style>