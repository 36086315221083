<template>
  <div class="row">
    <div class="col-6">
      <select class="form-control"
              v-model="form.settings.funding_source">
        <option value="">Select Funding Source</option>
        <option v-for="(fundingInstrument, i) in fundingInstruments"
                :value="fundingInstrument.id"
                :key="'fundingInstrument_'+i">{{ fundingInstrument.description }}</option>
      </select>
    </div>
  </div>

  <div class="card mt-3">
    <div class="card-header">
      We will create one ad group with one ad to start with
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <div class="input-group">
              <input type="text" class="form-control" id="campaignPeriod">
              <div class="input-group-append">
              <span class="input-group-text" id="basic-addon2">
                <i class="flaticon-calendar"></i>
              </span>
              </div>
            </div>
          </div>
        </div>

        <!--        <div class="col-md-6 ms-auto">
                  <div class="form-group">
                    <div class="custom-control custom-checkbox checkbox-primary">
                      <input type="checkbox"
                             class="custom-control-input"
                             v-model="form.settings.no_end_date"
                             @change="changeNoEndDate($event)"
                             id="no_end_date">
                      <label class="custom-control-label"
                             for="no_end_date">No end date</label>
                    </div>
                  </div>
                </div>-->
      </div>

      <div class="row">
        <div class="col-3">
          <div class="form-group">
            <label>Daily Budget</label>
            <input class="form-control"
                   v-model="form.settings.daily_budget"
                   type="number">
          </div>
        </div>
        <div class="col-3">
          <div class="form-group">
            <label>Total Budget (Optional)</label>
            <input class="form-control"
                   v-model="form.settings.total_budget"
                   type="number">
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <advance :formData="form" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Advance from "@/views/create_campaign/twitter_wizard/step3/Advance.vue";
export default {
  components: {Advance},
  props: ['formData', 'errors', 'fundingInstruments'],
  data() {
    return {
      form: {},
      products: []
    }
  },
  beforeMount() {
    this.form = this.formData;
  },
  mounted() {
    this.dateRangePickerInit(false);
  },
  beforeUpdate() {
    this.form = this.formData;
  },
  watch: {
    errors: {
      handler(newValue) {
        this.allErrors = newValue;
      },
      deep: true
    },
    formData: {
      handler(newValue) {
        this.$emit('update:formData', newValue);
      },
      deep: true
    },
  },
  methods: {
    dateRangePickerInit() {
      let self = this;

      window.$("#campaignPeriod").daterangepicker({
            startDate: window.moment(self.form.settings.start_date),
            endDate: window. moment(self.form.settings.end_date),
            minDate: window.moment().startOf("hour"),
            locale: {
              format: 'YYYY-MM-DD'
            },
          },
          function (t, a) {
            self.form.settings.start_date = t.format('YYYY-MM-DD');
            self.form.settings.end_date = a.format('YYYY-MM-DD');
          }
      )
    },
  }
}
</script>