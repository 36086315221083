<template>
  <h6 class="font-weight-bold text-black mt-3">{{ title }}</h6>

  <select class="select2"
          :id="'select_targeting_criteria_' + type"
          style="width: 100%"
          multiple="multiple"
          data-placeholder="Search">
  </select>
</template>
<script>
export default {
  props: ['type', 'title', 'targetingCriteria', 'targetingType'],
  data() {
    return {
      targetingCriteriaForm: this.targetingCriteria
    };
  },
  watch: {
    ad(newValue) {
      this.targetingCriteriaForm = newValue;
    }
  },
  mounted() {
    this.initListsSelect2();
  },
  methods: {
    initListsSelect2() {
      let self = this;
      const user = JSON.parse(localStorage.getItem('user')) || null;
      const account = JSON.parse(localStorage.getItem('account')) || null;

      const headers = {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      };

      if (user && account) {
        headers['Authorization'] = `Bearer ${user.token}`;
        headers['Account'] = `${account.id}`;
      }

      const selectElement = window.$(`#select_targeting_criteria_${this.type}`);

      selectElement.select2({
        ajax: {
          delay: 250,
          url: process.env.VUE_APP_API_BASE_URL + `/twitter/targeting_criteria/${self.type}`,
          dataType: 'json',
          headers: headers
        },
        minimumInputLength: 3,
      });

      const previousValues = self.targetingCriteriaForm[self.targetingType].map(option => ({
        id: option.id,
        text: option.name
      }));

      previousValues.forEach(option => {
        const newOption = new Option(option.text, option.id, true, true);
        selectElement.append(newOption).trigger('change');
      });

      selectElement.on('change', function () {
        const selectedOptions = selectElement.select2('data');
        self.targetingCriteriaForm[self.targetingType] = selectedOptions.map(option => ({
          id: option.id,
          name: option.text
        }));
      });
    },
  }
}
</script>