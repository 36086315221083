<template>
  <div class="row">
    <div class="col-6">
      <div class="card">
        <div class="card-header">Campaign Info</div>
        <div class="card-body">
          <div class="table-responsive">
            <table class="table table-bordered table-sm">
              <tbody>
              <tr>
                <th>Amazon Account</th>
                <td>{{ amazonAdAccount?.accountInfo.name}}</td>
              </tr>
              <tr>
                <th>X Account</th>
                <td>{{ twitterAccount?.name }}</td>
              </tr>
              <tr>
                <th>Campaign Name</th>
                <td>{{ form.amazon_attribution.campaign_name }}</td>
              </tr>
              <tr>
                <th>Funding Source</th>
                <td>{{ fundingSource?.description }}</td>
              </tr>
              <tr>
                <th>ASIN</th>
                <td>{{ form.amazon_attribution.asin }}</td>
              </tr>
              <tr>
                <th>Objective</th>
                <td>{{ form.objective.campaign_objective }}</td>
              </tr>
              <tr>
                <th>Campaign Date</th>
                <td>{{ form.settings.start_date + ' - ' + form.settings.end_date }}</td>
              </tr>
              <tr>
                <th>Daily Budget</th>
                <td>${{ form.settings.daily_budget }}</td>
              </tr>
              <tr>
                <th>Total Budget</th>
                <td v-if="form.settings.total_budget">${{ form.settings.total_budget }}</td>
                <td v-else></td>
              </tr>
              <tr>
                <th>Status</th>
                <td>
                  <select class="form-control form-control-sm"
                          v-model="form.status">
                    <option value="DRAFT">Draft</option>
                    <option value="PAUSED">Paused</option>
                    <option value="ACTIVE">Active</option>
                  </select>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="col-6">
      <TwitterPreview :twitterProfile="twitterProfile"
                      v-for="(ad, i) in form.ads"
                      :key="`ad_preview_${i}`"
                      :ad="ad" />
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import TwitterPreview from "@/views/create_campaign/twitter_wizard/step11/TwitterPreview.vue";

export default {
  components: {TwitterPreview},
  props: ['formData', 'errors', 'accounts', 'fundingInstruments', 'twitterProfile'],
  data() {
    return {
      form: {},
    }
  },
  computed: {
    ...mapGetters({
      amazonAdAccounts: 'adAccounts/getAmazonAccounts',
    }),
    amazonAdAccount() {
      return this.amazonAdAccounts.find(account => account.profileId == this.form.amazon_attribution.amazon_profile_id) || null;
    },
    twitterAccount() {
      return this.accounts.find(account => account.id == this.form.account) || null;
    },
    fundingSource() {
      return this.fundingInstruments.find(funding => funding.id == this.form.settings.funding_source) || null;
    }
  },
  beforeMount() {
    this.form = this.formData;
  },
  beforeUpdate() {
    this.form = this.formData;
  },
  watch: {
    errors: {
      handler(newValue) {
        this.allErrors = newValue;
      },
      deep: true
    },
    formData: {
      handler(newValue) {
        this.$emit('update:formData', newValue);
      },
      deep: true
    },
  },
}
</script>